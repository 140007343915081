
































import { Vue, Prop, Component } from 'vue-property-decorator';
import { IOrderBumpRelatedProduct } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import GeneralHelper from '@/shared/helpers/general';

@Component({})
export default class OrderBumpRelatedItem extends Vue {
  @Prop({ required: true }) relatedItem: IOrderBumpRelatedProduct;

  formatPrice(priceInCents) {
    const price = priceInCents / 100;
    return priceInCents ? GeneralHelper.currency(price) : 'R$ 0,00';
  }
}
